body #menu-custom {
    border-bottom: 0px solid rgba(34,36,38,.15);
}

.circle-login-button {
    background-color: #6C63FF;
    width: 180px;
    height: 50px;
    border-width: 0px;
    border-radius: 15px;
    color:white;
    font-weight: 600;
    border-color: white
}

#menu-custom {
    margin-left: 100px; 
    margin-right: 100px
}

@media (max-width: 1014px)
{
  .non-mobile-menu
  {
    display: none;
  }
}

@media (min-width: 1015px)
{
  .mobile-menu
  {
    display: none;
  }
}

@media (min-width: 1700px)
{
  #menu-custom {
    margin-left: 300px; 
    margin-right: 300px
  }
}

@media (min-width: 1573px) and (max-width: 1699)
{
  #menu-custom {
    margin-left: 250px; 
    margin-right: 250px
  }
}

@media (max-width: 600px) {
  #menu-custom {
      margin-left: auto;
      margin-right: auto;
  }
}